import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import Bootstrap from './Bootstrap';
import { Alert } from './components/organisms';
import { MessageTypes } from './pages/InfoView/messages.const';
import Providers from './Providers';
import Events from './services/events.service';
import { ERROR_DETAILS } from './utils/serverErrorHandler';

Events.init();
const staleTime = 10 * 60 * 1000;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime,
      retry: false,
    },
  },
});

const App: React.FC = () => (
  <QueryClientProvider client={queryClient}>
    <Router>
      <Providers>
        <Sentry.ErrorBoundary
          fallback={({ error, resetError }): JSX.Element => (
            <Alert
              message={ERROR_DETAILS.SERVER_ERROR.MESSAGE}
              messageType={MessageTypes.ERROR_SERVER}
              open={!!error}
              handleClose={resetError}
            />
          )}
        >
          <Bootstrap />
        </Sentry.ErrorBoundary>
      </Providers>
    </Router>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

export default App;
