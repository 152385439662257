import { FirebaseApp, initializeApp } from 'firebase/app';
import { getRemoteConfig, fetchAndActivate, getAll, Value } from 'firebase/remote-config';
import { getEnv } from './env';

const fetchTime = getEnv('REACT_APP_FIREBASE_REMOTE_CONFIG_FETCH_TIME');

const firebaseConfig = {
  apiKey: getEnv('REACT_APP_FIREBASE_APIKEY'),
  authDomain: getEnv('REACT_APP_FIREBASE_AUTHDOMAIN'),
  projectId: getEnv('REACT_APP_FIREBASE_PROJECT_ID'),
  storageBucket: getEnv('REACT_APP_FIREBASE_STORAGE_BUCKET'),
  messagingSenderId: getEnv('REACT_APP_FIREBASE_MESSAGE_SENDER_ID'),
  appId: getEnv('REACT_APP_FIREBASE_APP_ID'),
};

export const firebase = (): FirebaseApp => {
  return initializeApp(firebaseConfig);
};

export async function getRemoteConfigs(): Promise<Record<string, Value> | null> {
  try {
    firebase();

    const remoteConfig = getRemoteConfig();
    if (!remoteConfig) return null;
    remoteConfig.settings.minimumFetchIntervalMillis = fetchTime ? Number(fetchTime) : 0;
    await fetchAndActivate(remoteConfig);
    return getAll(remoteConfig);
  } catch (error) {
    return null;
  }
}
