import Braze from './braze.service';
import Analytics from './analytics.service';
import Universal from './universal.service';

import { EventData, EventHandler } from '../interfaces/events.interface';

export const eventHandlers: EventHandler[] = [Braze, Analytics, Universal];

const logEvent = (eventData: EventData, payload?: Record<string, unknown>): void => {
  eventHandlers.forEach(handler => handler.logEvent(eventData, payload));
};

const init = (): void => eventHandlers.forEach(handler => handler.init());

const Events = { logEvent, init };

export default Events;
