import appboy from '@braze/web-sdk';
import { IPlan } from '../interfaces/plan.interface';
import { EventData } from '../interfaces/events.interface';
import { getEnv } from '../utils/env';

const BRAZE_API_KEY = getEnv('REACT_APP_BRAZE_API_KEY');
const BRAZE_SDK_ENDPOINT = getEnv('REACT_APP_BRAZE_SDK_ENDPOINT');

const init = (): void => {
  appboy.initialize(BRAZE_API_KEY, {
    baseUrl: BRAZE_SDK_ENDPOINT,
  });
  appboy.display.automaticallyShowNewInAppMessages();

  appboy.openSession();
};

const setUser = (userId: string): void => appboy.changeUser(userId);

const logEvent = (event: EventData, payload?: Record<string, unknown>): boolean => {
  return appboy.logCustomEvent(event.action, payload);
};

const planPurchase = (plan: IPlan): boolean => {
  const {
    id,
    value: { price, currency },
  } = plan;

  return appboy.logPurchase(String(id), price, currency);
};

const Braze = {
  init,
  setUser,
  logEvent,
  planPurchase,
};

export default Braze;
