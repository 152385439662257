import { useContext, useEffect } from 'react';
import jwtDecode from 'jwt-decode';

import { Spinner } from './components/atoms';
import { useUser } from './hooks/useUser';
import Routes from './routes';
import Braze from './services/braze.service';
import Analytics from './services/analytics.service';
import GoogleUniversalService from './services/universal.service';
import { UserStatus } from './interfaces/userData.interface';
import Sentry from './services/sentry.service';
import { DriverContext } from './contexts/DriverContext';

const Bootstrap: React.FC = () => {
  const { publicId } = useContext(DriverContext);
  const { isLoading, user } = useUser();

  useEffect(() => {
    if (!user) return;
    Braze.setUser(user.user_contact.tembici_email);
    Sentry.setUser(publicId);
    if (user.user_status === UserStatus.loggedIn) {
      const { id } = jwtDecode<{ id: number }>(user.refresh_token);
      Analytics.setUser(String(id));
      GoogleUniversalService.setUser(String(id));
    }
  }, [publicId, user]);

  return !isLoading ? <Routes /> : <Spinner />;
};

export default Bootstrap;
